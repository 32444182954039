<template>
  <div id="segunda-via">
    <nav class="navbar navbar-expand-md fixed-top bg-transparent navbar-light">
      <div class="collapse navbar-collapse" id="main-menu"></div>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#main-menu"
        aria-controls="main-menu"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
    </nav>

    <!-- Modals -->
    <LoginFastAccessModalCompleto />
    <div class="via-facil">
      <div class="container">
        <div class="content">
          <div>
            <div class="icones">
              <img
                src="../assets/img/icones-home/icone-segunda-via-white.png"
                class="Segunda via de boleto"
              />
            </div>
          </div>
           <div>
            <h5><b>DIGIMAISFÁCIL</b></h5>
            <p style="font-size: 1.4rem"> 
              Seja bem-vindo ao portal de cobrança do Digi+!
            </p>
            <p style="font-size: 1.2rem"> 
              Clique no botão abaixo, e acesse com o CPF do cliente e seu usuário (mesmo código LJ e<br/>senhas de acesso ao aplicativo “CDC Digimais”):<br/><br/>
              1. Segunda via de carnê (20 dias após a efetivação do contrato);<br/>
              2. Boleto de liquidação (contratos em dia);<br/>
              3. Antecipação de parcelas com desconto;<br/>
              4. Parcelas com atraso até 10 dias.<br/>
            </p>
            <a
              class="btn-boleto"
              href="forms/login-form-Fast.html"
              data-remote="false"
              data-toggle="modal"
              data-target="#login-modal-Fast-Completo"
              >Gerar Carnê/2º via de Boleto
            </a>
            <br/>
            <br/>
            <p style="font-size: 1rem"> 
              Ou<br/><br/>
              Entre em contato com as nossas centrais de atendimento: <br/>4020 3300 | 0800 646 7676, de segunda a sexta das 8h às 20h e sábados das 8h às 14h (exceto feriados).
            </p>
          </div> 
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import EventBus from "../event-bus";
// import LoginModal from "../components/LoginModal";
// import CadastroModal from "../components/CadastroModal";
// import NovaSenhaModal from "../components/NovaSenhaModal";
//import ApiPortalAutoAtendimento from "../api_portal_auto_atendimento/src/index";
import LoginFastAccessModalCompleto from "../components/LoginFastAccessModalCompleto";

export default {
  name: "SegundaVia",
  components: {
    LoginFastAccessModalCompleto,
  },
  mounted() {
    var modal_needfix = true;
    $(".modal").on("shown.bs.modal", function () {
      var modalscope = $(this);
      modalscope.css({ paddingRight: "" });
      if (modal_needfix) {
        window.addEventListener("resize", function () {
          requestAnimationFrame(function () {
            // be sure to act after the pluggin script
            modalscope.css({ paddingRight: "" });
          });
        });
        modal_needfix = false;
      }
    });

    const self = this;
    setTimeout(function () {
      var element = document.getElementById("popupModal");

      if (element != null) {
        if (!self.hasClass(element, "openModal")) {
          element.classList.add("openModal");
        }
      }
    }, 1000);
  },
  methods: {
    // showLogin(event) {
    //   if (event) {
    //     event.preventDefault();
    //   }
    //   EventBus.$emit("eventShowLogin");
    // },
    // showCadastro: function (event) {
    //   if (event) {
    //     event.preventDefault();
    //   }
    //   EventBus.$emit("eventShowCadastro");
    // },
    showLoginFast: function (event) {
      if (event) {
        event.preventDefault();
      }
      EventBus.$emit("eventShowLoginFast");
    },
  },
};
</script>
<style>
</style>
